import { Headset, UserType } from "../components/user/Dashboard"
import { formatDate } from "./format"

export const trialDaysLeftText = (user: UserType): string | undefined => {
  if (!user) {
    return
  }

  const headsets: Headset[] = user.headset

  if (isTrialAvailable(user)) {
    const headset = headsets.find(hs => hs.trialActivated === false)
    const endDate = new Date()
    endDate.setDate(endDate.getDate() + headset!.trialNumberDays)
    const endDateFormatted = formatDate({ date: endDate, fullMonth: true })

    return `You have a free membership available. Please provide a credit card in order to activate your membership. You will not be charged until ${endDateFormatted}.`
  } else {
    return `Need to purchase a membership?`
  }
}

export const isTrialAvailable = (user: UserType): boolean => {
  //no longer offering trials this way
  return false
  // if (!user) return false

  // const hasMembership = !!user.membership
  // const hasRegisteredHeadset = user.headset && user.headset.length > 0

  // return !hasMembership && hasRegisteredHeadset ? true : false
}
