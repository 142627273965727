import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"

import Typography from "@material-ui/core/Typography"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import OrderDetailRow from "../orders/OrderDetailRow"
import { SubscriptionData, SubscriptionProvider } from "./Subscriptions"
import { formatCurrencyString, formatDate } from "../../../utils/format"
import { Grid, Hidden } from "@material-ui/core"
import { SubscriptionPayment } from "./SubscriptionPayment"
import {
  billingFormInputs,
  paymentFormInputs,
} from "../../../checkout/shared/checkoutFormFields"
import { FormContextProvider } from "../../../contexts/formContext"
import { Dividor } from "../../ui/line"
import { PaymentMethod } from "@stripe/stripe-js"
import SubAndTotalRow from "../../cart/SubAndTotalRow"
import { Button } from "../../ui/Button"
import { SubscriptionCancel } from "./SubscriptionCancel"
import { Currency } from "../../../checkout/types/checkoutTypes"

const useRowStyles = makeStyles({
  root: {
    minWidth: 275,
    position: "relative",
  },
  cardContent: {},
  title: {
    fontSize: 14,
  },
  moreExp: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    zIndex: 5,
  },
  productTitle: {
    color: "var(--color-teal)",
    fontFamily: "var(--font-family-header)",
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
    letterSpacing: 0,
  },
})

interface SubscriptionDetailProps {
  subscription: SubscriptionData
  membershipStatus?: "active" | "inactive" | "trialing" | "none" | undefined
  pmtMethods?: PaymentMethod[]
}

const formatStatus = (status: string, cancelAtPeriodEnd: boolean): string => {
  if (cancelAtPeriodEnd) {
    return "Cancelled"
  }

  switch (status) {
    case "active":
      return "Active"
    case "incomplete":
      return "Incomplete"
    case "incomplete_expired":
      return "Incompelete / Expired"
    case "trialing":
      return "Free Trial Period"
    case "past_due":
      return "Past Due"
    case "unpaid":
      return "Payment Required"
    default:
      return status
  }
}

// TODO: Include "your invoices can be viewed..."

const renderProviderMessage = (provider: SubscriptionProvider) => {
  switch (provider) {
    case "apple":
      return "Your membership was purchased through the Apple App Store. Your invoices can be viewed and your membership can be managed on your iOS device in the subscription settings."
    case "google":
      return "Your membership was purchased through the Google Play Store. our invoices can be viewed and your membership can be managed on your device in the subscription settings."
    default:
      return ""
  }
}

export const SubscriptionDetail = ({
  subscription,
  membershipStatus,
  pmtMethods,
}: SubscriptionDetailProps) => {
  const [open, setOpen] = React.useState(false)
  const [cancelDialoge, setCancelDialoge] = React.useState(false)
  const classes = useRowStyles()
  const { created, status, provider } = subscription

  const taxes = subscription.lineItems.filter(
    item => item.lineType === "sales_tax"
  )
  const subtotal = subscription.lineItems.find(
    item => item.lineType === "subtotal"
  )
  const total = subscription.lineItems.find(item => item.lineType === "total")

  const subTypeLine = subscription.lineItems.find(
    item => item.lineType === "product_none_shippable"
  )

  const formatPurchaseAmount = (amount: number): string => {
    return formatCurrencyString({
      value: amount,
      currency: total?.currency.toLowerCase() ?? "usd",
    })
  }

  const isStripe = provider === "stripe"
  const isApple = provider === "apple"
  const isGoogle = provider === "google"
  const isActive = status === "active"
  const membershipPurchasedHeadsetNotRegistered =
    subscription.trialDaysOnHeadsetConnect &&
    subscription.trialDaysOnHeadsetConnect > 0 &&
    !subscription.headsetConnectedTrialActivated

  const [year, confirmationCode] = subscription.confirmationCode
    ? subscription.confirmationCode.split("-")
    : ["", subscription.paymentIntentId]
  return (
    <>
      <Grid
        className={`${classes.root}`}
        container
        spacing={3}
        key={"subOverview_" + subscription.subscriptionId}
      >
        <Grid item sm={12} md={5}>
          {isStripe && (
            <Typography
              className={`${classes.title} subscription-history-confirmation-code`}
              color="textSecondary"
              gutterBottom
            >
              Confirmation: {confirmationCode}{" "}
            </Typography>
          )}
          <Typography
            className={`${classes.productTitle}`}
            color="textSecondary"
            gutterBottom
          >
            {isStripe && subTypeLine && subTypeLine.description}
            {!isStripe &&
              isGoogle &&
              "Sens.ai Subscription - Google Play Store"}
            {!isStripe && isApple && "Sens.ai Subscription - Apple App Store"}
          </Typography>
          <h4>
            <span>
              {membershipPurchasedHeadsetNotRegistered
                ? "Your subscription will activate when you connect your Headset to the Sens.ai App"
                : `Activated: ${formatDate({
                    date: new Date(created.formatted),
                    fullMonth: false,
                  })}`}
            </span>
          </h4>
          {!isActive && isStripe && !membershipPurchasedHeadsetNotRegistered && (
            <h4>
              <span>{formatStatus(status)}</span>
            </h4>
          )}
          {!isStripe && (
            <Typography
              className={`${classes.title}`}
              color="textSecondary"
              gutterBottom
            >
              {renderProviderMessage(provider)}
            </Typography>
          )}
        </Grid>

        <Grid item sm={12} md={4}>
          {isStripe && !membershipPurchasedHeadsetNotRegistered && (
            <>
              <Typography
                className={`${classes.title}`}
                color="textSecondary"
                gutterBottom
              >
                Next Charge:{" "}
                {subscription.cancelAtPeriodEnd
                  ? "cancelled"
                  : formatDate({
                      date: new Date(subscription.paidUntilDate.formatted),
                      fullMonth: false,
                    })}
              </Typography>
              <h4>
                <span>
                  {subscription.cancelAtPeriodEnd
                    ? "None"
                    : formatPurchaseAmount(total?.unitAmount ?? 0)}
                </span>
              </h4>
            </>
          )}
        </Grid>
        <Grid item sm={12} md={3}>
          {isStripe && (
            <>
              <Typography
                className={`${classes.title}`}
                color="textSecondary"
                gutterBottom
              >
                Status:
              </Typography>
              <h4>{formatStatus(status, subscription.cancelAtPeriodEnd)}</h4>
            </>
          )}
        </Grid>
        {isStripe && (
          <IconButton
            id={"expand-" + subscription.confirmationCode}
            aria-label="expand row"
            size="small"
            className={classes.moreExp}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        )}
      </Grid>
      {isStripe && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid container spacing={6}>
            <Grid item sm={12}>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Subscription Details
                </Typography>
                <Hidden xsDown>
                  <OrderDetailRow
                    desc="Description"
                    unitAmount="Amount"
                    quantity=""
                    unitQuantStr="Total Price"
                    currency={total!.currency as Currency}
                  />
                </Hidden>
                {subTypeLine && (
                  <OrderDetailRow
                    key={subTypeLine.id}
                    desc={`${subTypeLine.description} x${subTypeLine.quantity}`}
                    quantity={subTypeLine.quantity}
                    unitAmount={subTypeLine.unitAmount}
                    currency={subTypeLine.currency as Currency}
                  />
                )}
                {taxes && taxes.length && (
                  <>
                    {taxes.map(t => {
                      return (
                        <SubAndTotalRow
                          key={t.lineType + t.id}
                          value={t.unitAmount}
                          currency={t.currency as Currency}
                          desc={t.description}
                          isTotal={false}
                        />
                      )
                    })}
                  </>
                )}

                <SubAndTotalRow
                  key={"total_" + total!.id}
                  value={total!.unitAmount}
                  currency={total!.currency as Currency}
                  desc={total!.description}
                  isTotal={total!.description === "Total"}
                />

                <Typography variant="h6" gutterBottom component="div">
                  Payment History
                </Typography>
                <Hidden xsDown>
                  <OrderDetailRow
                    desc="Date"
                    unitAmount="Status"
                    quantity=""
                    unitQuantStr="Total"
                    currency={total!.currency as Currency}
                  />
                </Hidden>

                {subscription.invoices.map(invoice => (
                  <OrderDetailRow
                    key={"invoice_" + invoice.id}
                    desc={`${new Date(
                      invoice.periodStart.formatted
                    ).toLocaleDateString()} - ${new Date(
                      invoice.periodEnd.formatted
                    ).toLocaleDateString()}`}
                    quantity=""
                    unitAmount={invoice.status}
                    unitSmallText="Status: "
                    unitQuantStr={formatPurchaseAmount(invoice.total)}
                    currency={total!.currency as Currency}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      )}
      {(membershipStatus === "trialing" || membershipStatus === "active") &&
        !subscription.cancelAtPeriodEnd &&
        isStripe && (
          <>
            <Dividor />
            <Grid
              container
              spacing={3}
              className={classes.root}
              alignItems="center"
              key={"payment_" + subscription.subscriptionId}
            >
              <Grid item sm={12} md={8}>
                <FormContextProvider
                  initialInputs={{ ...billingFormInputs, ...paymentFormInputs }}
                >
                  <SubscriptionPayment
                    paymentId={subscription.providerPaymentMethodId}
                    pmtMethods={pmtMethods}
                  />
                </FormContextProvider>
              </Grid>
              <Grid item sm={12} md={4}>
                <div style={{ textAlign: "center" }}>
                  <Button
                    sectionId="user_payment_methods"
                    type="text"
                    title="Cancel Membership"
                    size="control"
                    id={"cancel-" + subscription.confirmationCode}
                    onClick={() => setCancelDialoge(true)}
                  />
                </div>
              </Grid>
              {cancelDialoge && (
                <SubscriptionCancel
                  subscription={subscription}
                  setShowHide={setCancelDialoge}
                  cancelDialoge={cancelDialoge}
                />
              )}
            </Grid>
          </>
        )}
    </>
  )
}
