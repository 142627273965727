import React from "react"
import SEO from "../Seo"
import { SectionDividor } from "../ui/line"
import { Card, Container, Grid } from "@material-ui/core"
import { OrderData } from "./orders/orderTypes"
import OrdersTableRow from "./orders/OrdersTableRow"
import Link from "../ui/link"
import Icon from "../ui/Icon"
import { Stripe, StripeElements } from "@stripe/stripe-js"
import Profile from "./Profile"
import Addresses from "./Addresses"
import { UserAddress } from "../../ts/interfaces"
import Subscriptions, { SubscriptionData } from "./subscriptions/Subscriptions"
import { graphql, useStaticQuery } from "gatsby"

export interface Headset {
  id: string
  trialActivated: boolean
  trialNumberDays: number
  trialStartDate: {
    formatted: string
  }
}

export type UserType = {
  firstName: string
  lastName: string
  email: string
  shippingAddresses?: UserAddress[]
  subscriptions: SubscriptionData[]
  membership: null | {
    status: "active" | "inactive" | "trialing"
    endDate: {
      formatted: string
    }
    subscription: SubscriptionData
  }
  expiredMemberships: {
    expiredSubscription: SubscriptionData[]
  }[]
  headset: Headset[]
}
export interface DashboardProps {
  latestOrder: OrderData
  user: UserType
  refetchData: ({
    variables: { email },
  }: {
    variables: { email: string }
  }) => void
  setRefetching: (value: boolean) => void
  elements?: StripeElements
  stripe?: Stripe
  refreshOrder: () => void
}

const Dashboard = (props: DashboardProps) => {
  const { latestOrder, user, refetchData, setRefetching, refreshOrder } = props

  const { strapi } = useStaticQuery(
    graphql`
      query {
        strapi {
          activate {
            activePage
          }
        }
      }
    `
  )
  const hideSubscriptions =
    !strapi || !strapi.activate || !strapi.activate.activePage

  const hasExpiredMemberships =
    user?.expiredMemberships && user?.expiredMemberships.length > 0

  return (
    <>
      <SEO title="Your Sens.ai Account" />

      <Container maxWidth="lg">
        <SectionDividor
          isTop={true}
          core={{ divider: "topGradientLine", animate: false }}
        />
        <h1>Your Account</h1>
        <Grid container spacing={6} style={{ marginTop: "2rem" }}>
          <Grid item xs={12} sm={6}>
            <Profile user={user} />
          </Grid>
          {user && (
            <Grid item xs={12} sm={6}>
              <Card>
                <Addresses
                  addressType="shipping"
                  user={user}
                  refetchData={refetchData}
                  setRefetching={setRefetching}
                />
              </Card>
            </Grid>
          )}
        </Grid>
        {!hideSubscriptions && (
          <>
            <h4 style={{ marginTop: "3rem" }}>Membership</h4>
            <Subscriptions
              subscriptions={user?.subscriptions}
              user={user}
              showHistory={false}
            />
            {hasExpiredMemberships && (
              <p>
                <Link to="/user/membership" title="View membership history">
                  Membership History &emsp;{" "}
                  <Icon iconType="Solid" iconName="chevron-right" />
                </Link>
              </p>
            )}
          </>
        )}
        <h4 style={{ marginTop: "3rem" }}>Most Recent Order</h4>
        {latestOrder && (
          <OrdersTableRow key={latestOrder.id} order={latestOrder} />
        )}
        <p>
          <Link to="/user/orders" title="View all orders">
            View all orders &emsp;{" "}
            <Icon iconType="Solid" iconName="chevron-right" />
          </Link>
        </p>
      </Container>
    </>
  )
}

export default Dashboard
