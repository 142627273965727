/**
 * Component to restrict content to logged in users
 */

import React, { FC } from "react"
import { RouteComponentProps } from "@reach/router"

interface PrivateRouteProps extends RouteComponentProps {
  component: FC
  props?: Record<string, any>
}

const PrivateRoute = ({
  component: Component,
  props,
  location,
}: PrivateRouteProps) => {
  if (props) {
    return <Component {...props} />
  }
  return <Component />
}

export default PrivateRoute
