import React, { useEffect, useState } from "react"

import { FormContextType, useForm } from "../../../contexts/formContext"
import { getBillingAddressFromPM } from "../../../hooks/payment-methods/billingAddressUtil"
import { usePaymentMethodsAdd } from "../../../hooks/payment-methods/usePaymentMethodsAdd"
import { AuthStateType, useAuthState } from "../../../contexts/authContext"
import { PaymentMethod, StripeCardElementChangeEvent } from "@stripe/stripe-js"
import SubscriptionPaymentAdd from "./SubscriptionPaymentAdd"
import SubscriptionPaymentEdit from "./SubscriptionPaymentEdit"

export interface SubscriptionPaymentFormProps {
  onAddNewPaymentMethod: () => void
  setIsAddingNewCard: (val: boolean) => void
  isAddingNewCard: boolean
  formState: any
  formActions: any
  authState: AuthStateType
  cardElementOptions: any
  handleOnCardElementChange: (e: StripeCardElementChangeEvent) => void
  setShowCardError: (val: boolean) => void
  showCardError: boolean
  errorToDisplay: string
  processingSave: boolean
  createAndAssignPaymentMethod: () => void
  subscriptionPm?: PaymentMethod
  heading?: string
}

const cardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      backgroundColor: "#FFFFFF",
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "var(--color-error)",
    },
  },
}

export const SubscriptionPayment = ({
  paymentId,
  pmtMethods,
}: {
  paymentId: string
  pmtMethods?: PaymentMethod[]
}) => {
  const { formState, formActions } = useForm() as FormContextType
  const authState = useAuthState() as AuthStateType

  const [subscriptionPm, setSubscriptionPm] = useState<any>(undefined)
  const [errorToDisplay, setErrorToDisplay] = useState("")
  const [showCardError, setShowCardError] = useState(false)
  const [isAddingNewCard, setIsAddingNewCard] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isPaying, setIsPaying] = useState(false)

  const [
    selectedPaymentMethod,
    processingSave,
    cardDone, //true when the card is ready
    createAndAssignPaymentMethod,
  ] = usePaymentMethodsAdd(
    setErrorToDisplay,
    formState,
    formActions,
    setIsLoading,
    setIsPaying
  )

  useEffect(() => {
    if (cardDone) {
      if (window) {
        //force refresh
        window.location.reload()
      }
    }
  }, [cardDone])

  const setInitialValuesForNewCard = () => {
    const inputChangeEvents = [
      {
        target: {
          name: "cardHolderName",
          input: "input",
          value:
            authState.firstName && authState.lastName
              ? `${authState.firstName} ${authState.lastName}`
              : formState.inputs?.firstName?.value &&
                formState.inputs?.firstName?.value
              ? `${formState.inputs.firstName.value} ${formState.inputs.lastName.value}`
              : "",
          error: "",
        },
      },
      {
        target: {
          name: "cardHolderEmail",
          input: "input",
          value: authState.email
            ? authState.email
            : formState.inputs.email?.value || "",
          error: "",
        },
      },
    ]

    if (subscriptionPm && subscriptionPm.card.address) {
      const address = getBillingAddressFromPM(subscriptionPm)

      formActions.setFormData({
        cardHolderName: address.cardHolderName,

        billingCountry: address.country,
        billingCity: address.city,
        billingProvince: address.province,
        billingStreet: address.street,
        billingUnit: address.unit,
        billingPhone: address.phone,
        billingPostal: address.postal,
      })
    }
    if (formActions && formActions.inputHandler instanceof Function) {
      for (const event of inputChangeEvents) {
        formActions.inputHandler(event)
      }
    }
  }
  useEffect(() => {
    if (isAddingNewCard) {
      setInitialValuesForNewCard()
    }
  }, [isAddingNewCard])

  const handleOnCardElementChange = (e: StripeCardElementChangeEvent) => {
    setShowCardError(false)
    const inputChangeEvent = {
      target: {
        name: "cardStripe",
        error: "",
        errMsgOverride: "",
        complete: e.complete,
      },
    }
    setErrorToDisplay("")

    if (e.error) {
      inputChangeEvent.target.errMsgOverride = e.error.message
      inputChangeEvent.target.error = e.error.message

      //  setErrorToDisplay(e.error.message)
    }
    formActions.inputHandler(inputChangeEvent)
  }

  useEffect(() => {
    if (pmtMethods?.length) {
      const payM = pmtMethods.find((pm: any) => pm.id === paymentId)
      if (payM) {
        setSubscriptionPm(payM)
      }
    }
  }, [pmtMethods])

  const onAddNewPaymentMethod = () => {
    setErrorToDisplay("")
    setIsAddingNewCard(true)
  }

  if (!subscriptionPm) {
    return (
      <SubscriptionPaymentAdd
        onAddNewPaymentMethod={onAddNewPaymentMethod}
        setIsAddingNewCard={setIsAddingNewCard}
        isAddingNewCard={isAddingNewCard}
        formState={formState}
        formActions={formActions}
        authState={authState}
        cardElementOptions={cardElementOptions}
        handleOnCardElementChange={handleOnCardElementChange}
        setShowCardError={setShowCardError}
        showCardError={showCardError}
        errorToDisplay={errorToDisplay}
        processingSave={processingSave}
        createAndAssignPaymentMethod={createAndAssignPaymentMethod}
      />
    )
  }

  return (
    <SubscriptionPaymentEdit
      onAddNewPaymentMethod={onAddNewPaymentMethod}
      subscriptionPm={subscriptionPm}
      setIsAddingNewCard={setIsAddingNewCard}
      isAddingNewCard={isAddingNewCard}
      formState={formState}
      formActions={formActions}
      authState={authState}
      cardElementOptions={cardElementOptions}
      handleOnCardElementChange={handleOnCardElementChange}
      setShowCardError={setShowCardError}
      showCardError={showCardError}
      errorToDisplay={errorToDisplay}
      processingSave={processingSave}
      createAndAssignPaymentMethod={createAndAssignPaymentMethod}
    />
  )
}
