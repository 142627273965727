import React from "react"

import OrdersTableRow from "./OrdersTableRow"
import { OrderData } from "./orderTypes"

interface OrdersTableProps {
  orders: OrderData[]
}

const OrdersTable = ({ orders }: OrdersTableProps) => {
  return (
    <>
      {orders.length === 0 && <p>We couldnt find any orders at this time.</p>}
      {orders.map(order => (
        <OrdersTableRow key={order.id} order={order} />
      ))}
    </>
  )
}

export default OrdersTable
