import React, { useEffect, useState } from "react"
import {
  Card,
  CardContent,
  makeStyles,
  IconButton,
  Grid,
  ListItemAvatar,
  Avatar,
  ListItem,
  ListItemText,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import AddIcon from "@material-ui/icons/Add"
import styled from "styled-components"
import { UserAddress } from "../../ts/interfaces"
import { FormContextProvider } from "../../contexts/formContext"
import { useApiMutation } from "../../hooks/useApiMutation"
import { AddressCreate } from "../ui/AddressCreate"
import {
  billingFormInputs,
  shippingFormInputs,
} from "../../checkout/shared/checkoutFormFields"

const useRowStyles = makeStyles({
  root: {
    position: "relative",
  },
  buttonAction: {
    position: "absolute",
    top: "0rem",
    right: "0rem",
    zIndex: 5,
  },
})

const StyledListItem = styled.li<{ disabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  p {
    margin: 2px 0;
    color: ${({ disabled }) => (disabled ? "rgba(0, 0, 0, 0.26)" : "initial")};
  }
`

const IconsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
`

const StyledListItemText = styled(ListItemText)`
  .MuiTypography-body1,
  .MuiTypography-body2 {
    font-family: Rubik;
  }
`

enum AddressType {
  billing = "billing",
  shipping = "shipping",
}

interface AddressProps {
  addressType: AddressType
  user: {
    firstName: string
    lastName: string
    email: string
    shippingAddresses: UserAddress[] | []
    billingAddresses?: UserAddress[] | []
  }
  refetchData: ({
    variables: { email },
  }: {
    variables: { email: string }
  }) => void
  setRefetching: (value: boolean) => void
}

const NameText = styled.p`
  font-weight: 600;
`

const Addresses = (props: AddressProps) => {
  const { addressType, user, refetchData, setRefetching } = props

  // CA - this SUX that we used a tuple and not an object here??
  // const [
  //   listPaymentMethods,
  //   pmtMethodsData,
  //   pmtMethodsLoading,
  //   pmtMethodsError,
  //   pmtMethodsMsg,
  //   isAddingNewCard,
  //   selectedPaymentMethod,
  //   setSelectedPaymentMethod,
  //   setIsAddingNewCard,
  // ] = usePaymentMethods(true, false, undefined, {})

  const [isAddNewAddressMode, setIsAddNewAddressMode] = useState<boolean>(false)

  const [errorDisplay, setErrorDisplay] = useState("")

  const classes = useRowStyles()

  const [
    RemoveShippingAddress,
    {
      loading: removeShippingLoading,
      error: removeShippingError,
      data: removeShippingData,
    },
  ] = useApiMutation("account", "RemoveShippingAddress")

  useEffect(() => {
    const errorMessage =
      (removeShippingError && removeShippingError.message) || ""
    setErrorDisplay(errorMessage)
  }, [removeShippingError])

  useEffect(() => {
    if (removeShippingData && user && user.email) {
      refetchData({
        variables: {
          email: user.email,
        },
      })
    }
  }, [removeShippingData])

  const handleDeleteShippingAddress = async (address: UserAddress) => {
    try {
      RemoveShippingAddress({
        variables: {
          addressId: address.id,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const clearAddingNew = () => {
    setIsAddNewAddressMode(false)
  }

  const clearAndRefetch = () => {
    setIsAddNewAddressMode(false)
    refetchData({
      variables: {
        email: user.email,
      },
    })
  }
  let addressList =
    addressType === "shipping" && user
      ? user.shippingAddresses
      : user.billingAddresses
  addressList = addressList || []

  return (
    <Card>
      <CardContent>
        <h4 className={classes.root}>
          {props.addressType === "shipping"
            ? "Shipping Addresses"
            : "Billing Addresses"}
        </h4>

        <>
          {isAddNewAddressMode ? (
            <FormContextProvider
              initialInputs={
                addressType === "shipping"
                  ? shippingFormInputs
                  : billingFormInputs
              }
            >
              <AddressCreate
                addressType={addressType}
                onCancel={clearAddingNew}
                onDone={clearAndRefetch}
              />
            </FormContextProvider>
          ) : addressList.length === 0 ? (
            <p>You currently do not have any saved {addressType} addresses.</p>
          ) : (
            <>
              <ul>
                {addressList.map((address: UserAddress) => (
                  <StyledListItem disabled={removeShippingLoading}>
                    <NameText>
                      {address.firstName} {address.lastName}
                    </NameText>
                    <p>
                      {address.street}
                      {address.unit ? `, unit ${address.unit}` : ""}
                    </p>
                    <p>
                      {address.city},{" "}
                      {address.province ? `${address.province}, ` : ""}{" "}
                      {address.country}
                    </p>
                    <p>{address.postal}</p>

                    {/* We disable the delete if this is the active billing card address */}
                    {/* {selectedPaymentMethod &&
                      selectedPaymentMethod.card.address.street !==
                        address.street && (
                        <IconsWrapper>
                          <IconButton
                            disabled={removeShippingLoading}
                            onClick={() => {
                              if (addressType === "shipping") {
                                handleDeleteShippingAddress(address)
                              }
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </IconsWrapper>
                      )} */}
                  </StyledListItem>
                ))}
              </ul>
            </>
          )}
        </>

        {errorDisplay && <p className="error">{errorDisplay}</p>}
        {!isAddNewAddressMode && (
          <ListItem
            key={`add_${addressType}_address`}
            id={`add_${addressType}_address`}
            button
            onClick={() => {
              setIsAddNewAddressMode(true)
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <StyledListItemText
              primary={"Add a new address"}
              secondary={`Add a new ${addressType} address`}
            />
          </ListItem>
        )}
      </CardContent>
    </Card>
  )
}

export default Addresses
