import { FormInputState } from "../../utils/validations"
import { Countries } from "../../utils/Countries"
import { UserAddress } from "../../ts/interfaces"

export const getBillingAddressDetailsForStripCard = (
  inputs: Record<string, FormInputState>,
  authEmail?: string | null | undefined
) => {
  const c = Countries.get(
    inputs.billingCountry.value || inputs.shippingCountry.value
  )

  return {
    name: inputs.cardHolderName.value,
    email: inputs.cardHolderEmail.value || authEmail || "",
    address: {
      city: inputs.billingCity.value || inputs.shippingCity.value,
      country: c?.code || "", // wants a 2 letter code here
      line1: inputs.billingStreet.value || inputs.shippingStreet.value,
      line2: inputs.billingUnit?.value || inputs.shippingUnit?.value || "",
      postal_code: inputs.billingPostal.value || inputs.shippingPostal.value,
      state: inputs.billingProvince.value || inputs.shippingProvince.value,
    },
    phone: inputs.billingPhone.value || inputs.shippingPhone.value,
  }
}

export const getBillingAddressFromPM = (pm: any) => {
  let country: String = ""

  Countries.forEach(c => {
    if (c?.code === pm.card.address.country) {
      country = c.name
    }
  })

  const userAddress: UserAddress = {
    ...pm.card.address,
    country,
    cardHolderName: pm.card.address.firstName,
  }
  return userAddress
}
