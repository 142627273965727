import React from "react"

import { SubscriptionPaymentFormProps } from "./SubscriptionPayment"
import SubscriptionPaymentForm from "./SubscriptionPaymentForm"

const SubscriptionPaymentEdit = ({
  onAddNewPaymentMethod,
  subscriptionPm,
  setIsAddingNewCard,
  isAddingNewCard,
  formState,
  formActions,
  authState,
  cardElementOptions,
  handleOnCardElementChange,
  setShowCardError,
  showCardError,
  errorToDisplay,
  processingSave,
  createAndAssignPaymentMethod,
}: SubscriptionPaymentFormProps) => {
  return (
    <SubscriptionPaymentForm
      heading="Payment Method"
      subscriptionPm={subscriptionPm}
      onAddNewPaymentMethod={onAddNewPaymentMethod}
      setIsAddingNewCard={setIsAddingNewCard}
      isAddingNewCard={isAddingNewCard}
      formState={formState}
      formActions={formActions}
      authState={authState}
      cardElementOptions={cardElementOptions}
      handleOnCardElementChange={handleOnCardElementChange}
      setShowCardError={setShowCardError}
      showCardError={showCardError}
      errorToDisplay={errorToDisplay}
      processingSave={processingSave}
      createAndAssignPaymentMethod={createAndAssignPaymentMethod}
    />
  )
}

export default SubscriptionPaymentEdit
