import React, { useState } from "react"

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { Hidden } from "@material-ui/core"
import OrderDetailRow from "./OrderDetailRow"
import SubAndTotalRow from "../../cart/SubAndTotalRow"
import { OrderData, LineItem } from "./orderTypes"
import { Currency } from "../../../checkout/types/checkoutTypes"

interface OrdersTableRowProps {
  order: OrderData
}

const OrdersDetails = ({ order }: OrdersTableRowProps) => {
  const { currency } = order

  const getLines = () => {
    const itemLines: LineItem[] = []
    let subtotalLine = {} as LineItem
    let totalLine = {} as LineItem
    let discountLine = {} as LineItem
    let shippingLine = {} as LineItem
    let taxLines = [] as LineItem[]

    for (const line of order.lineItems) {
      if (line.quantity > 0 && line.lineType !== "discount") {
        itemLines.push(line)
      } else if (line.description === "Subtotal") {
        subtotalLine = line
      } else if (line.description === "Total") {
        totalLine = line
      } else if (line.lineType === "discount") {
        discountLine = line
      } else if (line.lineType === "shipping") {
        shippingLine = line
      } else if (line.lineType === "sales_tax") {
        taxLines.push(line)
      }
    }

    const lines = [
      ...itemLines,
      subtotalLine,
      discountLine,
      shippingLine,
      ...taxLines,
      totalLine,
    ]

    return lines.map(item => {
      if (item.quantity > 0 && item.lineType !== "discount") {
        return (
          <OrderDetailRow
            key={item.id}
            desc={`${item.description} x${item.quantity}`}
            quantity={item.quantity}
            unitAmount={item.unitAmount}
            currency={item.currency as Currency}
          />
        )
      }
      return (
        <SubAndTotalRow
          key={item.id}
          value={item.unitAmount}
          currency={item.currency as Currency}
          desc={item.description}
          isTotal={item.description === "Total"}
        />
      )
    })
  }

  return (
    <Box margin={2}>
      <Typography variant="h6" gutterBottom component="div">
        Order Details
      </Typography>
      <Hidden xsDown>
        <OrderDetailRow
          desc="Description"
          unitAmount="Amount"
          quantity=""
          unitQuantStr="Total Price"
          currency={currency as Currency}
        />
      </Hidden>
      {getLines()}
    </Box>
  )
}

export default OrdersDetails
