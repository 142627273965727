import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { useAuthState, useAuthDispatch } from "../../contexts/authContext"
// import { useForm } from "../../hooks/formHook"
import { FormContextType, useForm } from "../../contexts/formContext"
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  CircularProgress,
  FormControl,
} from "@material-ui/core"
import CreateIcon from "@material-ui/icons/Create"
import { FormInput } from "../ui/Input"
import { Button } from "../ui/Button"
import { getLs, setLs } from "../../utils/localStorage"
import { useApiMutation } from "../../hooks/useApiMutation"

interface UpdateUserResponse {
  firstName: string
  lastName: string
  updated: { formatted: string }
}

const useRowStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  buttonAction: {
    position: "absolute",
    top: "0rem",
    right: "0rem",
    zIndex: 5,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: "1rem",
  },
}))

const StatusContainer = styled.div`
  text-align: center;
  margin-top: 1rem;
`
const ProfileItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ProfileForm = () => {
  const authState = useAuthState()
  const authDispatch = useAuthDispatch()

  const { updated } = authState

  const [updateNameMutation, { loading, error, data }] = useApiMutation(
    "account",
    "UpdateUser"
  )

  const [
    updatePasswordMutation,
    { loading: loadingPassword, error: errorPassword, data: dataPassword },
  ] = useApiMutation("account", "ChangePassword")

  const classes = useRowStyles()
  const { formState, formActions } = useForm() as FormContextType
  const [isUpdateNameMode, setIsUpdateNameMode] = useState(false)
  const [isUpdatePasswordMode, setIsUpdatePasswordMode] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (data && data.UpdateUser) {
      const { firstName, lastName } = data.UpdateUser as UpdateUserResponse
      const updated = data.UpdateUser.updated.formatted
      authDispatch({
        type: "LOGIN",
        payload: {
          ...authState,
          firstName: formState.inputs.firstName.value,
          lastName: formState.inputs.lastName.value,
          updated,
        },
      })
      const lsUser = getLs("userData")
      lsUser.firstName = firstName
      lsUser.lastName = lastName
      lsUser.updated = updated
      setLs("userData", lsUser)
      handleNoUpdateMode()
    }
  }, [data])

  useEffect(() => {
    if (dataPassword) {
      handleNoUpdateMode()
    }
  }, [dataPassword])

  useEffect(() => {
    if (errorPassword) {
      setErrorMessage("There was an error updating your password.")
    } else if (error) {
      setErrorMessage("There was an error updating your name.")
    }
  }, [error, errorPassword])

  useEffect(() => {
    formActions.setSkipValidationFunction((field: string): boolean => {
      if (isUpdateNameMode) {
        return ["currentPassword", "newPassword", "confirmPassword"].includes(
          field
        )
      } else if (isUpdatePasswordMode) {
        return ["firstName", "lastName"].includes(field)
      }
      return false
    })
  }, [isUpdatePasswordMode, isUpdateNameMode])

  const handleNoUpdateMode = () => {
    setIsUpdateNameMode(false)
    setIsUpdatePasswordMode(false)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!formActions.isValid()) {
      return
    }

    if (isUpdateNameMode) {
      updateNameMutation({
        variables: {
          input: {
            firstName: formState.inputs.firstName.value,
            lastName: formState.inputs.lastName.value,
            email: authState.email,
            updated: { formatted: updated },
          },
        },
      })
    } else if (isUpdatePasswordMode) {
      updatePasswordMutation({
        variables: {
          input: {
            email: authState.email,
            currentPassword: formState.inputs.currentPassword.value,
            newPassword: formState.inputs.newPassword.value,
          },
        },
      })
    }
  }

  return (
    <Card>
      <CardContent>
        <h4 className={classes.root}>
          Profile{" "}
          {(isUpdateNameMode || isUpdatePasswordMode) && (
            <IconButton
              aria-label="edit"
              id="user-edit-profile"
              size="small"
              className={classes.buttonAction}
              onClick={handleNoUpdateMode}
            >
              <CreateIcon />
            </IconButton>
          )}
        </h4>
        {!isUpdateNameMode && !isUpdatePasswordMode && (
          <ul>
            <ProfileItem>
              <span>
                Name:
                <em id="user-profile-name">{` ${authState.firstName} ${authState.lastName}`}</em>
              </span>
              <IconButton
                aria-label="edit-name"
                id="user-edit-name"
                size="small"
                onClick={() => setIsUpdateNameMode(!isUpdateNameMode)}
              >
                <CreateIcon />
              </IconButton>
            </ProfileItem>
            <ProfileItem>
              <span>
                Email:<em id="user-profile-email">{` ${authState.email}`}</em>
              </span>
            </ProfileItem>
            <ProfileItem id="user-profile-password">
              <span>
                Password:
                <em>
                  {" "}
                  &#183; &#183; &#183; &#183; &#183; &#183; &#183; &#183;
                </em>
              </span>
              <IconButton
                aria-label="edit-password"
                id="user-edit-password"
                size="small"
                onClick={() => setIsUpdatePasswordMode(!isUpdatePasswordMode)}
              >
                <CreateIcon />
              </IconButton>
            </ProfileItem>
          </ul>
        )}
        {(isUpdateNameMode || isUpdatePasswordMode) && (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {isUpdateNameMode && (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormInput
                        fieldId="user-first-name"
                        fieldName="firstName"
                        inputs={formState.inputs}
                        inputHandler={formActions.inputHandler}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormInput
                        fieldId="user-last-name"
                        fieldName="lastName"
                        inputs={formState.inputs}
                        inputHandler={formActions.inputHandler}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {isUpdatePasswordMode && (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormInput
                        fieldId="user-current-password"
                        fieldName="currentPassword"
                        inputs={formState.inputs}
                        inputHandler={formActions.inputHandler}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormInput
                        fieldId="user-new-password"
                        fieldName="newPassword"
                        inputs={formState.inputs}
                        inputHandler={formActions.inputHandler}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <FormInput
                        fieldId="user-confirm-password"
                        fieldName="confirmPassword"
                        inputs={formState.inputs}
                        inputHandler={formActions.inputHandler}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              {(error || errorPassword) && (
                <StatusContainer>
                  {
                    <p id="user-profile-update-error" className="error">
                      {errorMessage}
                    </p>
                  }
                </StatusContainer>
              )}
              <Grid container justify="center" className={classes.submit}>
                <Button
                  type="primary"
                  submit={true}
                  icon="key"
                  id="updateProfileSubmit"
                  sectionId="update"
                  title="Update Profile"
                  disabled={loading || loadingPassword}
                />
              </Grid>
              {loading && (
                <StatusContainer>
                  <CircularProgress />
                </StatusContainer>
              )}
            </Grid>
          </form>
        )}
      </CardContent>
    </Card>
  )
}

export default ProfileForm
