import React from "react"

import SubscriptionPaymentForm from "./SubscriptionPaymentForm"
import { SubscriptionPaymentFormProps } from "./SubscriptionPayment"

const SubscriptionPaymentAdd = ({
  onAddNewPaymentMethod,
  setIsAddingNewCard,
  isAddingNewCard,
  formState,
  formActions,
  authState,
  cardElementOptions,
  handleOnCardElementChange,
  setShowCardError,
  showCardError,
  errorToDisplay,
  processingSave,
  createAndAssignPaymentMethod,
}: SubscriptionPaymentFormProps) => {
  return (
    <SubscriptionPaymentForm
      heading="Add Payment Method"
      onAddNewPaymentMethod={onAddNewPaymentMethod}
      setIsAddingNewCard={setIsAddingNewCard}
      isAddingNewCard={isAddingNewCard}
      formState={formState}
      formActions={formActions}
      authState={authState}
      cardElementOptions={cardElementOptions}
      handleOnCardElementChange={handleOnCardElementChange}
      setShowCardError={setShowCardError}
      showCardError={showCardError}
      errorToDisplay={errorToDisplay}
      processingSave={processingSave}
      createAndAssignPaymentMethod={createAndAssignPaymentMethod}
    />
  )
}

export default SubscriptionPaymentAdd
