import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import API from "../../../server/serverInterface"
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core"
import { UserType } from "../Dashboard"
import { formatDate } from "../../../utils/format"
import { navigate } from "gatsby"

interface LineItem {
  id: string
  description: string
  currency: string
  quantity: number
  unitAmount: number
  lineType:
    | "product_none_shippable"
    | "sales_tax"
    | "subtotal"
    | "total"
    | "product_shippable"
}

interface Invoice {
  id: string
  created: {
    formatted: string
  }
  periodStart: {
    formatted: string
  }
  periodEnd: {
    formatted: string
  }
  status: string
  total: number
}

export interface SubscriptionData {
  id: string
  paymentIntentId: string
  status: string
  currentPeriodEnd: {
    formatted: string
  }
  subscriptionId: string
  cancelAtPeriodEnd: boolean
  lineItems: LineItem[]
  created: {
    formatted: string
  }
  confirmationCode: string
  user: UserType
  invoices: Invoice[]
  providerPaymentMethodId: string
}

interface SubscriptionsProps {
  subscription: SubscriptionData
  cancelDialoge: boolean
  setShowHide: Dispatch<SetStateAction<boolean>> //(show: boolean) => {}
}

export const SubscriptionCancel = ({
  subscription,
  cancelDialoge,
  setShowHide,
}: SubscriptionsProps) => {
  const [
    cancelSubscriptionInProgress,
    setCancelSubscriptionInProgress,
  ] = useState(false)

  const [isError, setIsError] = useState(false)

  const handleSubscriptionCancel = async (subscriptionId: string) => {
    setCancelSubscriptionInProgress(true)
    // create a setup intent
    try {
      const cancel = await API.execute("billing", "CancelSubscription", {
        subscriptionId,
        provider: "stripe",
      })
      setShowHide(false)
      if (window) {
        //force refresh
        window.location.reload()
      }
    } catch (err) {
      console.error(`Error cancelling subscription '${subscriptionId}'`, err)
      setIsError(true)
    } finally {
      setCancelSubscriptionInProgress(false)
    }
  }

  return (
    <>
      <Dialog
        id={"cancel-subscription-dialog"}
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={cancelDialoge}
      >
        <DialogTitle id="confirmation-dialog-title">
          Cancel Membership
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant={"body1"}>
            Are you sure you want to cancel your membership? Your subscription
            will be cancelled effective{" "}
            {formatDate({
              date: new Date(subscription.currentPeriodEnd.formatted),
              fullMonth: false,
            })}
            .
          </Typography>
          {cancelSubscriptionInProgress && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}
          {isError && (
            <p className="error">
              Sorry, there was an error cacnellig your subscription. Please try
              again later.
            </p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setShowHide(false)}
            color="primary"
            disabled={cancelSubscriptionInProgress}
          >
            Cancel
          </Button>
          <Button
            id={"confirm-cancel"}
            onClick={() =>
              handleSubscriptionCancel(subscription.subscriptionId)
            }
            color="primary"
            disabled={cancelSubscriptionInProgress}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
