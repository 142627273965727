import React from "react"
import { useMemo } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import { getCartProduct } from "../../../utils/stripejs"
import { formatCurrencyString } from "../../../utils/format"
import { OrderData } from "./orderTypes"
import { Grid } from "@material-ui/core"
import { CartProduct } from "../../../ts/interfaces"
import ProductImg from "./ProductImg"
import OrdersDetails from "./OrderDetail"

const useRowStyles = makeStyles({
  root: {
    minWidth: 275,
    position: "relative",
    marginBottom: "2rem",
  },
  cardContent: {},
  title: {
    fontSize: 14,
  },
  moreExp: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    zIndex: 5,
  },
  productTitle: {
    color: "var(--color-teal)",
    fontFamily: "var(--font-family-header)",
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
    letterSpacing: 0,
  },
})

interface OrdersTableRowProps {
  order: OrderData
}

const formatStatus = (status: string, paymentProvider: string): string => {
  switch (status) {
    case "requires_payment_method":
      return "Pending Payment"
    case "requires_action":
      return "Requires Action"
    case "succeeded":
      return "Successful"
    case "cancelled":
      return "Cancelled"
    case "processing":
      return "Processing"
    case "failed":
      return "Failed"
    case "created":
      return "Pending"
    case "requires_capture":
      return paymentProvider != "indiegogo"
        ? "Requires Capture"
        : "Pending Shipping"
    case "refunded":
    case "disputed_refunded":
      return "Refunded"
    default:
      return status
  }
}

const formatShippingStatus = (fulfilmentStatus: string): string => {
  switch (fulfilmentStatus) {
    case "awaiting_allocation":
      return "Pending"
    case "awaiting_picking":
      return "Processing"
    case "awaiting_despatch":
      return "Awaiting Courrier"
    case "QUEUED_BACKORDER":
      return "Queued"
    case "backorder":
      return "Backorder"
    case "cancelled":
      return "Cancelled"
    case "despatched":
      return "Dispatched"
    default:
      return fulfilmentStatus.replace("_", " ").toLowerCase()
  }
}

const getReservedPrice = (order: OrderData) => {
  const gross: number = order.metadata.find(meta => meta.key === "pt_price")
    ?.value as number
  const discount: number = order.metadata.find(
    meta => meta.key === "pt_discount"
  )?.value as number

  return { gross, discount }
}

const getShippingStatus = (order: OrderData) => {
  // TODO we should add a shipping status if required or check the line items
  return "Not Required"
}

const OrdersTableRow = ({ order }: OrdersTableRowProps) => {
  const [open, setOpen] = React.useState(false)
  const classes = useRowStyles()
  const {
    created,
    status,
    amount,
    currency,
    fulfilmentStatus,
    paymentProvider,
  } = order
  const fullDate = new Date(created.formatted)
  const { gross, discount } = getReservedPrice(order)
  const shippingStatus = getShippingStatus(order)

  const metaDataForPriceCard = ["pt_price", "pt_discount"]
  const metaDataKeys = order.metadata.map(meta => meta.key)

  let mostExpensive: CartProduct | null = null
  let mostPrice = 0
  let description = ""
  let lineDescription = ""
  const [year, confirmationCode] = order.confirmationCode
    ? order.confirmationCode.split("-")
    : ["", order.paymentIntentId]
  order.lineItems.sort((a, b) => a.lineNumber - b.lineNumber)
  order.lineItems.forEach(async item => {
    if (
      item.lineType === "product_shippable" ||
      item.lineType === "product_none_shippable"
    ) {
      lineDescription += `${item.description} x${item.quantity} `
    }
    const cartProd = getCartProduct(item.productId || "")
    if (cartProd) {
      const n =
        item.quantity > 1
          ? `${cartProd?.name} (${item.quantity})`
          : `${cartProd?.name}`
      description += description === "" ? n : `, ${n}`
      if (
        cartProd &&
        cartProd.prices.usd &&
        cartProd.prices.usd?.unit_amount > mostPrice
      ) {
        mostExpensive = cartProd
        mostPrice = cartProd.prices.usd?.unit_amount
      }
    }
  })
  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={6}>
            <Grid item sm={12} md={3}>
              {mostExpensive && <ProductImg cartProd={mostExpensive} />}
              {!mostExpensive && <h4>{lineDescription}</h4>}
            </Grid>
            <Grid item sm={12} md={5}>
              <Typography
                className={`${classes.title} order-history-confirmation-code`}
                color="textSecondary"
                gutterBottom
              >
                Confirmation: {confirmationCode}
              </Typography>
              <Typography
                className={`${classes.productTitle}`}
                color="textSecondary"
                gutterBottom
              >
                {description}
              </Typography>
              <h4>
                <span className="order-history-item-total">
                  {formatCurrencyString({
                    value: amount,
                    currency: currency.toUpperCase(),
                  })}
                  {` ${currency.toUpperCase()}`}
                </span>
                <span>&emsp;&mdash;&emsp;</span>
                <span style={{ whiteSpace: "nowrap" }}>
                  {new Intl.DateTimeFormat("en", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                  }).format(fullDate)}
                </span>
              </h4>
            </Grid>
            <Grid item sm={12} md={4}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Payment Status
              </Typography>
              <h4>
                {`${formatStatus(status, paymentProvider)} `}
                {!!order.refundAmount &&
                  order.status === "refunded" &&
                  formatCurrencyString({
                    value: order.refundAmount,
                    currency: currency.toUpperCase(),
                  })}
              </h4>
              {order.status === "refunded" && (
                <p>
                  <em>
                    Refunds take 5-10 days to appear on your credit card
                    statement.
                  </em>
                </p>
              )}
              {(order.tracking ||
                (fulfilmentStatus &&
                  fulfilmentStatus.toLowerCase() !== "undefined")) && (
                <>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Shipping Status
                  </Typography>
                  <h4>{`${formatShippingStatus(fulfilmentStatus)}`}</h4>
                </>
              )}
            </Grid>
          </Grid>
          {(order.tracking ||
            (fulfilmentStatus &&
              fulfilmentStatus.toLowerCase() !== "undefined")) && (
            <>
              {order.tracking && (
                <>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {order.tracking.courierName} (
                    {order.tracking.trackingNumber})
                  </Typography>
                  <p>
                    <a
                      title={`Tracking ${order.tracking.courierName} (${order.tracking.trackingNumber})`}
                      href={order.tracking.trackingUrl}
                      target="_blank"
                    >
                      {order.tracking.trackingUrl}
                    </a>
                  </p>
                </>
              )}
            </>
          )}
        </CardContent>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          className={classes.moreExp}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <OrdersDetails order={order} />
        </Collapse>
      </Card>
    </>
  )
}

export default OrdersTableRow
