import React from "react"

import {
  Avatar,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core"
import CardBillingAddress from "../CardBillingAddress"
import { getBillingAddressFromPM } from "../../../hooks/payment-methods/billingAddressUtil"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import { FormInput } from "../../ui/Input"
import { CardElement } from "@stripe/react-stripe-js"
import { Address } from "../../ui/Address"
import { Button } from "../../ui/Button"
import CreateIcon from "@material-ui/icons/Create"
import TransitionsModal from "../../ui/Modal"
import { SubscriptionPaymentFormProps } from "./SubscriptionPayment"

const SubscriptionPaymentForm = ({
  onAddNewPaymentMethod,
  subscriptionPm,
  setIsAddingNewCard,
  isAddingNewCard,
  formState,
  formActions,
  authState,
  cardElementOptions,
  handleOnCardElementChange,
  setShowCardError,
  showCardError,
  errorToDisplay,
  processingSave,
  createAndAssignPaymentMethod,
  heading,
}: SubscriptionPaymentFormProps) => {
  return (
    <div style={{ position: "relative" }}>
      <h4>
        <span>{heading}</span>
        <IconButton
          style={{ position: "absolute", right: 0, marginRight: "2rem" }}
          aria-label="edit-name"
          id="edit-sub-payment"
          size="small"
          onClick={onAddNewPaymentMethod}
        >
          <CreateIcon />
        </IconButton>
      </h4>

      {subscriptionPm && (
        <Grid container spacing={1} key={"card_" + subscriptionPm.id}>
          <Grid item sm={12} md={4}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <CreditCardIcon />
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                id="card_subscription"
                primary={
                  subscriptionPm.card?.brand +
                  " *****" +
                  subscriptionPm.card?.last4
                }
                secondary={
                  "Exp: " +
                  subscriptionPm.card?.exp_month +
                  "/" +
                  subscriptionPm.card?.exp_year
                }
              />
            </ListItem>
          </Grid>
          <Grid item sm={12} md={4}>
            <CardBillingAddress
              allowEdit={false}
              paymentMethodId={subscriptionPm.id}
              cardAddress={getBillingAddressFromPM(subscriptionPm)}
            />
          </Grid>
        </Grid>
      )}

      <TransitionsModal setIsOpen={setIsAddingNewCard} isOpen={isAddingNewCard}>
        <Container maxWidth="sm">
          <Grid container spacing={3}>
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormInput
                    fieldId="payment-cardholderName"
                    fieldName="cardHolderName"
                    inputs={formState.inputs}
                    inputHandler={formActions.inputHandler}
                  />
                </FormControl>
              </Grid>
            </>

            {!authState.email && (
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <FormInput
                      fieldId="payment-cardHolderEmail"
                      fieldName="cardHolderEmail"
                      fieldType="email"
                      inputs={formState.inputs}
                      inputHandler={formActions.inputHandler}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <CardElement
                options={cardElementOptions}
                onChange={handleOnCardElementChange}
                onBlur={() => setShowCardError(true)}
              />
              {showCardError && formState.inputs.cardStripe.error && (
                <p className="error">{formState.inputs.cardStripe.error}</p>
              )}
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Address type="billing" includeCardHolderName={false} />
              </Grid>
            </Grid>
            {errorToDisplay && <p className="error">{errorToDisplay}</p>}

            {processingSave && (
              <div style={{ textAlign: "center", margin: "1rem" }}>
                <CircularProgress />
              </div>
            )}

            <div
              style={{
                textAlign: "center",
                margin: "1rem",
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Button
                sectionId="user_payment_methods"
                id={"cancel_save_card"}
                type="text"
                disabled={processingSave}
                title="Cancel"
                size="control"
                onClick={() => {
                  setIsAddingNewCard(false)
                }}
              />

              <Button
                sectionId="user_payment_methods"
                id={"save_card"}
                type="primary"
                icon={processingSave ? "lock" : "credit-card"}
                disabled={processingSave}
                title={processingSave ? "Processing..." : "Save Card"}
                onClick={() => {
                  if (!formActions.isValid()) {
                    return
                  }
                  createAndAssignPaymentMethod()
                }}
              />
            </div>
          </Grid>
        </Container>
      </TransitionsModal>
    </div>
  )
}

export default SubscriptionPaymentForm
