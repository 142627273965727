import React from "react"
import { FormContextProvider } from "../../contexts/formContext"
import ProfileForm from "./ProfileForm"
import { FormInputState } from "../../utils/validations"
import { UserType } from "./Dashboard"

const Profile = ({ user }: { user?: UserType }) => {
  const intialFormInput: Record<string, FormInputState> = {
    firstName: {
      value: user?.firstName,
      label: "First Name",
      validation: [
        { type: "required" },
        { type: "min", value: 2 },
        { type: "max", value: 150 },
      ],
    },
    lastName: {
      value: user?.lastName,
      label: "Last Name",
      validation: [
        { type: "required" },
        { type: "min", value: 2 },
        { type: "max", value: 150 },
      ],
    },
    currentPassword: {
      value: "",
      label: "Current Password",
      validation: [
        { type: "required" },
        { type: "min", value: 5 },
        { type: "max", value: 150 },
      ],
    },
    newPassword: {
      value: "",
      label: "New Password",
      validation: [
        { type: "required" },
        { type: "min", value: 5 },
        { type: "max", value: 150 },
      ],
    },
    confirmPassword: {
      value: "",
      label: "Confirm New Password",
      validation: [
        { type: "required" },
        { type: "min", value: 5 },
        { type: "max", value: 150 },
        { type: "match", value: "newPassword" },
      ],
    },
  }

  return (
    <FormContextProvider initialInputs={intialFormInput}>
      <ProfileForm />
    </FormContextProvider>
  )
}

export default Profile
