/**
 * A router for restricted client-only, non-SSR, dynamically generated pages
 * - gated directories must be to gatsby-plugin-create-client-paths options in gatsby-config.js
 */

import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import { graphql, navigate, useStaticQuery } from "gatsby"
import PrivateRoute from "../components/auth/PrivateRoute"
import Layout from "../components/Layout"
import Dashboard from "../components/user/Dashboard"
import Orders from "../components/user/orders/Orders"
import { CircularProgress } from "@material-ui/core"
import PaddedContainer from "../components/ui/PaddedContainer"
import Icon from "../components/ui/Icon"
import Subscriptions from "../components/user/subscriptions/Subscriptions"
import { useAuthState } from "../contexts/authContext"
import { getLs } from "../utils/localStorage"
import { useLazyApi } from "../hooks/useLazyApi"
import MembershipHistory from "../components/user/subscriptions/SubHistory"

const User = () => {
  const state = useAuthState()

  const confCode = getLs("lastConfirmationCode")
  const params = confCode ? { confCode: confCode.code } : {}

  const [fetchOrders, { loading, error, data, errMsg }] = useLazyApi(
    "billing",
    "GetUserOrders"
  )

  const { strapi } = useStaticQuery(
    graphql`
      query {
        strapi {
          activate {
            activePage
          }
        }
      }
    `
  )
  const hideSubscriptions =
    !strapi || !strapi.activate || !strapi.activate.activePage

  let timeout: number
  useEffect(() => {
    if (!state.isLoggedIn() && !timeout) {
      timeout = setTimeout(() => {
        navigate("/login")
      }, 1500)
    } else if (timeout) {
      clearTimeout(timeout)
    }
    fetchOrders({
      variables: {
        ...params,
        email: state.email,
      },
    })
    return () => clearTimeout(timeout)
  }, [state])

  const refetchOrders = async (params: { variables: { email: string } }) => {
    fetchOrders(params)
  }
  const refreshOrder = async () => {
    fetchOrders({
      variables: {
        ...params,
        email: state.email,
      },
    })
  }

  if (data && data.User && data.User.orders) {
    data.User.orders.sort(
      (a, b) =>
        new Date(b.created.formatted).getTime() -
        new Date(a.created.formatted).getTime()
    )
  }

  return (
    <Layout hasHero={false}>
      {loading && (
        <PaddedContainer>
          <div style={{ textAlign: "center" }}>
            <h2>Loading your account details...</h2>

            <CircularProgress style={{ margin: "2rem" }} />
            <h4>Please wait</h4>
          </div>
        </PaddedContainer>
      )}
      {error && (
        <PaddedContainer>
          <PaddedContainer>
            <div style={{ textAlign: "center" }}>
              <p>
                <Icon
                  iconName="exclamation-triangle"
                  iconType="Solid"
                  size="3x"
                  iconColor="var(--color-purple)"
                />
              </p>
              <h2>Something went wrong</h2>

              {errMsg && (
                <p className="large">
                  <em>Error: {errMsg}</em>
                </p>
              )}
              {!errMsg && (
                <p className="large">
                  <em>
                    We experienced an error retrieving your account information.
                    <br />
                    Please try again later.
                  </em>
                </p>
              )}
              <p>If this problem continues, please let us know!</p>
            </div>
          </PaddedContainer>
        </PaddedContainer>
      )}

      <Router>
        {!loading && !error && (
          <PrivateRoute
            component={Dashboard}
            props={{
              latestOrder: data?.User?.orders[0],
              user: data?.User,
              refreshOrder: refreshOrder,
              refetchData: refetchOrders,
            }}
            path="/user"
          />
        )}
        {!loading && !error && (
          <PrivateRoute
            component={Orders}
            props={{ orders: data?.User?.orders, loading, error }}
            path="/user/orders"
          />
        )}
        {!hideSubscriptions && !loading && !error && (
          <PrivateRoute
            component={MembershipHistory}
            props={{
              user: data?.User,
              loading,
              error,
            }}
            path="/user/membership"
          />
        )}
      </Router>
    </Layout>
  )
}
export default User
