import React, { useEffect, useState } from "react"
import { Grid } from "@material-ui/core"

import styled from "styled-components"

import { Address } from "./Address"
import { FormContextType, useForm } from "../../contexts/formContext"
import { useApiMutation } from "../../hooks/useApiMutation"
import { Button } from "./Button"
import {
  billingFields,
  shippingFields,
} from "../../checkout/shared/checkoutFormFields"

interface AddressProps {
  addressType: "billing" | "shipping"
  onDone: () => void
  onCancel: () => void
}

const ButtonsWrapper = styled.div`
  textalign: center;
  margin: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`

export const AddressCreate = (props: AddressProps) => {
  const { addressType, onCancel, onDone } = props

  const { formState, formActions } = useForm() as FormContextType

  const [errorDisplay, setErrorDisplay] = useState("")
  const [allDone, setAllDone] = useState(false)

  const [
    CreateShippingAddress,
    {
      loading: createShippingLoading,
      error: createShippingError,
      data: createShippingData,
    },
  ] = useApiMutation("account", "CreateShippingAddress")

  useEffect(() => {
    const errorMessage =
      (createShippingError && createShippingError.message) || ""
    setErrorDisplay(errorMessage)
  }, [createShippingError])

  useEffect(() => {
    if (createShippingData) {
      setAllDone(true)
      onDone()
    }
  }, [createShippingData])

  const handleAddressSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    formActions.setSkipValidationFunction((field: string): boolean => {
      if (addressType === "billing") {
        return shippingFields.includes(field)
      }
      return billingFields.includes(field)
    })

    if (!formActions.isValid()) {
      return
    }

    // TODO: we DON'T attach billing address to the user any longer.
    if (addressType === "shipping") {
      try {
        CreateShippingAddress({
          variables: {
            input: {
              firstName: formState.inputs[`${addressType}FirstName`].value,
              lastName: formState.inputs[`${addressType}LastName`].value,
              country: formState.inputs[`${addressType}Country`].value,
              province: formState.inputs[`${addressType}Province`].value,
              city: formState.inputs[`${addressType}City`].value,
              street: formState.inputs[`${addressType}Street`].value,
              unit: formState.inputs[`${addressType}Unit`].value,
              postal: formState.inputs[`${addressType}Postal`].value,
              phone: formState.inputs[`${addressType}Phone`].value,
              vat: formState.inputs[`${addressType}Vat`].value,
            },
          },
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  return allDone ? (
    <></>
  ) : (
    <form>
      <Grid container spacing={2}>
        <Address type={addressType} />
      </Grid>
      <ButtonsWrapper>
        <Button
          sectionId={`add_${addressType}_dialog`}
          id={"cancel_new_address"}
          type="text"
          disabled={createShippingLoading}
          title="Cancel"
          size="control"
          onClick={() => {
            setAllDone(true)
            onCancel()
          }}
        />

        <Button
          sectionId={`add_${addressType}_dialog`}
          id="confirm"
          type="primary"
          disabled={createShippingLoading}
          icon={createShippingLoading ? "lock" : "map-marker-alt"}
          title="Save Address"
          onClick={handleAddressSubmit}
        />
      </ButtonsWrapper>
      {errorDisplay && <p className="error">{errorDisplay}</p>}
    </form>
  )
}

export default AddressCreate
