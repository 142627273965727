import React, { useEffect, useState, ChangeEvent } from "react"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { PaymentMethod, StripeCardElement } from "@stripe/stripe-js"
import { AuthStateType, useAuthState } from "../../contexts/authContext"
import { useApiMutation } from "../useApiMutation"
import { getBillingAddressDetailsForStripCard } from "./billingAddressUtil"
import { getLs, removeLs, setLs } from "../../utils/localStorage"

export const usePaymentMethodsAdd = (
  setErrorToDisplay,
  formState,
  formActions,
  setIsPaying: (isPaying: boolean) => void,
  setIsLoading: (isLoading: boolean) => void
) => {
  const stripe = useStripe()
  const elements = useElements()
  const authState = useAuthState() as AuthStateType
  const [processingSave, setProcessingSave] = useState(false)
  const [cardDone, setCardDone] = useState(false)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethod | undefined
  >(undefined)

  const [
    createSetupIntent,
    {
      loading: createSetupIntentLoading,
      error: createSetupIntentError,
      data: createSetupIntentData,
      errMsg: createSetupIntentErrorMsg,
    },
  ] = useApiMutation("billing", "UpdatePaymentMethod")

  const [
    completeSetupIntent,
    {
      loading: completeSetupIntentLoading,
      error: completeSetupIntentError,
      data: completeSetupIntentData,
      errMsg: completeSetupIntentErrorMsg,
    },
  ] = useApiMutation("billing", "CompleteSetupIntent")

  /*
   * Save a card only if allowed
   */

  const createAndAssignPaymentMethod = async (): Promise<void> => {
    setCardDone(false)
    if (!elements || !stripe) {
      setErrorToDisplay("Could find payment processor")
      return
    }

    setProcessingSave(true)
    const cardElement = elements.getElement(CardElement)
    if (!cardElement) throw new Error("Please provide payment information")

    // Convert payment information collected by elements into a PaymentMethod object
    // that you safely pass to your server to use in an API call.
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement as StripeCardElement,
      billing_details: getBillingAddressDetailsForStripCard(
        formState.inputs,
        authState.email || ""
      ),
    })

    if (result.error) {
      setProcessingSave(false)
      setErrorToDisplay(result.error.message || "Could not save card")
      return
    } else if (!result.paymentMethod) {
      setProcessingSave(false)
      setErrorToDisplay("Could not save card")
      return
    }
    setSelectedPaymentMethod(result.paymentMethod)

    // create a setup intent
    await createSetupIntent({
      variables: { paymentMethodId: result.paymentMethod.id },
    })
  }

  const confirmCardSetup = async (setupIntentSecret: string): Promise<void> => {
    if (setupIntentSecret && stripe && selectedPaymentMethod) {
      setLs("selectedPaymentMethodId", selectedPaymentMethod.id)
      const payload = await stripe.confirmCardSetup(setupIntentSecret, {
        payment_method: selectedPaymentMethod.id,
      })

      if (payload.error) {
        setErrorToDisplay(
          payload.error.message || "We encountered an error with your card."
        )
      }

      setProcessingSave(false)
      // New card was added sucessfully
      setCardDone(true)
    } else {
      setErrorToDisplay(
        `We encountered an error with your card. ${createSetupIntentError?.message}` ||
          "We encountered an error with your card."
      )
      setProcessingSave(false)
      setIsPaying(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const selectedPaymentMethodId = getLs("selectedPaymentMethodId")
    if (selectedPaymentMethodId) {
      // We were redirected back from 3DSecure
      setProcessingSave(false)

      completeSetupIntent({
        variables: { paymentMethodId: selectedPaymentMethodId },
      })
    }
  }, [])

  useEffect(() => {
    if (createSetupIntentError) {
      setErrorToDisplay(
        `We encountered an error with your card. ${createSetupIntentError.message}` ||
          "We encountered an error with your card."
      )
    }
    if (completeSetupIntentError) {
      setErrorToDisplay(
        `We encountered an error with your card. ${completeSetupIntentError.message}` ||
          "We encountered an error with your card."
      )
    }
    if (createSetupIntentError || completeSetupIntentError) {
      setProcessingSave(false)
      setIsPaying(false)
      setIsLoading(false)
    }
  }, [createSetupIntentError, completeSetupIntentError])

  useEffect(() => {
    if (createSetupIntentData && createSetupIntentData.UpdatePaymentMethod) {
      if (createSetupIntentData.UpdatePaymentMethod.status !== "succeeded") {
        confirmCardSetup(createSetupIntentData.UpdatePaymentMethod.clientSecret)
      } else {
        // New card was added sucessfully
        removeLs("selectedPaymentMethodId")
        setProcessingSave(false)
        setCardDone(true)
      }
    }
  }, [createSetupIntentData])

  useEffect(() => {
    if (completeSetupIntentData) {
      // New card was added sucessfully
      removeLs("selectedPaymentMethodId")
      setProcessingSave(false)
      setCardDone(true)
    }
  }, [completeSetupIntentData])

  return [
    selectedPaymentMethod,
    processingSave,
    cardDone,
    createAndAssignPaymentMethod,
  ] as const
}
