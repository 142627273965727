import React from "react"
import { ApolloError } from "@apollo/client"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import OrdersTable from "./OrdersTable"
import { SectionDividor } from "../../ui/line"
import SEO from "../../Seo"
import { OrderData } from "./orderTypes"
import Link from "../../ui/link"
import Icon from "../../ui/Icon"

interface OrdersProps {
  orders: OrderData[]
  loading: boolean
  error: ApolloError | undefined
}

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}))

const Orders = ({ orders, loading, error }: OrdersProps) => {
  const classes = useStyles()

  return (
    <>
      <SEO title="Your Sens.ai Orders" />
      <Container maxWidth="lg" className={classes.container}>
        <Link
          style={{
            marginTop: "-5rem",
            marginBottom: "2rem",
            display: "block",
          }}
          to="/user"
          title="My Account"
        >
          <Icon iconType="Solid" iconName="chevron-left" /> &emsp; My Account
        </Link>
        <SectionDividor
          isTop={true}
          core={{ divider: "topGradientLine", animate: false }}
        />
        <h1>Your Orders</h1>

        {loading && <p>Loading...</p>}
        {error && <p>Something went wrong... please try again later.</p>}
        {orders && <OrdersTable orders={orders} />}
      </Container>
    </>
  )
}

export default Orders
