import React from "react"
import { makeStyles } from "@material-ui/core"
import styled from "styled-components"

import { UserAddress } from "../../ts/interfaces"
import { billingFormInputs } from "../../checkout/shared/checkoutFormFields"

const useRowStyles = makeStyles({
  root: {
    position: "relative",
    paddingBottom: 16,
  },
  buttonAction: {
    position: "absolute",
    top: "0rem",
    right: "0rem",
    zIndex: 5,
  },
})

const StyledListItem = styled.div<{ disabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  p {
    margin: 2px 0;
    color: ${({ disabled }) => (disabled ? "rgba(0, 0, 0, 0.26)" : "initial")};
  }
`

const IconsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
`

interface CardBillingAddressProps {
  paymentMethodId: string
  cardAddress: UserAddress
  title?: string
}

const CardBillingAddress = (props: CardBillingAddressProps) => {
  const { cardAddress, paymentMethodId } = props
  const classes = useRowStyles()

  // This is NOT great. We try to find the billing address saved on the user from the one that we saved to stripe. The only way to find
  // is based on a street and unit and not an id. If we FAIL to find the address we just give them back the stripe address
  const address = cardAddress

  const inputs = {
    ...billingFormInputs,
  }
  // inputs.cardHolderName.value = address.cardHolderName
  inputs.billingStreet.value = address.street
  inputs.billingPhone.value = address.phone
  inputs.billingCountry.value = address.country
  inputs.billingCity.value = address.city
  inputs.billingProvince.value = address.province
  inputs.billingPostal.value = address.postal
  inputs.billingUnit.value = address.unit

  return (
    <>
      {props.title && <h6 className={classes.root}>{props.title}</h6>}
      <>
        <StyledListItem disabled={false}>
          <p>
            {address.street}
            {address.unit ? `, unit ${address.unit}` : ""}
          </p>
          <p>
            {address.city}, {address.province ? `${address.province}, ` : ""}{" "}
            {address.country}
          </p>
          <p>{address.postal}</p>
        </StyledListItem>
      </>
    </>
  )
}

export default CardBillingAddress
